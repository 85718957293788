export default {
  methods: {
    getLine(passage) {
      let new_passage = passage.replace(/[\n]/g, "<br>");
      new_passage = new_passage.replace(
        /<br>/g,
        "</p><p style='margin-bottom:0;height:34px;white-space:nowrap;'>"
      );
      new_passage =
        "<p style='margin-bottom:0;height:34px;white-space:nowrap;'>" +
        new_passage +
        "</p>";
      return new_passage;
    }
  }
};
