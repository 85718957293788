<template>
  <div v-if="passage && type">
    <template v-if="type === 'DigitalSAT'">
      <div v-if="passage.content" class="passage-content" v-html="getPassage(passage.content,passage.below_content)" />
    </template>
    <div v-else>
      <h2 style="padding-left:24px;text-align:center;margin-bottom:20px">
        {{ passage.title }}
      </h2>
      <div>
        <div style="margin-bottom: 20px" v-if="passage.intro">
          <div class="passage-intro" v-html="passage.intro" />
        </div>
      </div>
      <div>
        <div v-if="passage.below_content">
          <h5>Passage I</h5>
        </div>
        <!-- <div class="passage-content" v-html="passage.content" /> -->
        <div v-if="passage.content" class="passage-content" v-html="getPassage(passage.content,passage.below_content)" />
      </div>
      <!-- <div v-if="passage.below_content" style="margin-top:20px">
        <div class="text-center"><h5>Passage II</h5></div>
        <div class="passage-content" v-html="passage.below_content" />
      </div> -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import satTest from "@/mixins/satTest";

export default {
  components: {},
  mixins: [satTest],
  props: ["passage", "type", "section"],
  data() {
    return {};
  },
  mounted() {
    this.addIntroMargin();
    this.addLineNumber();
    this.narrowImage();
    this.setQuestionNumberStyle();
    this.setQuestionNumberStyleWithBaseline();
  },

  methods: {
    getPassage(p1, p2){
      let passage = p1;
      if(p2){
        passage+= `<h5>Passage II</h5>${p2}`;
      }
      return passage;
    },
    narrowImage() {
      let passageContent = $(".passage-content");
      $.each(passageContent, function(key, val) {
        let line = 1;
        for (let i = 0; i < $(val).find("img").length; i++) {
          $(val).find("img")[i].style.cssText = "width: 100%";
        }
      });
    },
    addIntroMargin() {
      if (
        (this.type.toUpperCase() === "SAT" && this.section === "reading") ||
        (this.type.toUpperCase() === "ACT" && this.section === "reading")
      ) {
        let passageIntro = $(".passage-intro");
        $.each(passageIntro, function(key, val) {
          for (let i = 0; i <= $(val).find("p").length; i++) {
            if (
              $.trim($($(val).find("p")[i]).html()) != "" &&
              $.trim($($(val).find("pre")[i]).html()) != "&nbsp;" &&
              !$.trim($($(val).find("p")[i]).html()).includes("<img")
            ) {
              $($(val).find("p")[i]).prepend(
                "<b class='text-success' style='display: inline-block;width:30px'></b>"
              );
            }
          }
        });
      }
    },
    addLineNumber() {
      if (
        (this.type.toUpperCase() === "SAT" && this.section === "reading") ||
        (this.type.toUpperCase() === "ACT" && this.section === "reading")
      ) {
        // console.log(1);
        let passageContent = $(".passage-content");
        $.each(passageContent, function(key, val) {
          let line = 1;

          for (let i = 0; i <= $(val).find("p").length; i++) {
            
            if (
              $.trim($($(val).find("p")[i]).html()) != "" &&
              $.trim($($(val).find("pre")[i]).html()) != "&nbsp;" &&
              !$.trim($($(val).find("p")[i]).html()).includes("<img")
            ) {
              let num = line / 5;
              if ((line == 1 || parseInt(num) == num) && line > 1) {
                $($(val).find("p")[i]).prepend(
                  `<b class="text-success" style="text-align: right; display: inline-block;width:30px; padding-right: 4px">${line}</b>`
                );
              } else if (i === 3) {
                $($(val).find("p")[i]).prepend(
                  "<span class=\"text-success\" style=\"text-align: right; display: inline-block;width:30px; font-style: italic; padding-right: 4px\">line</span>"
                );
              } else {
                $($(val).find("p")[i]).prepend(
                  "<b class='text-success' style='display: inline-block;width:30px'></b>"
                );
              }
              line = line + 1;
            }
          }
        });
      }
    },
    setQuestionNumberStyle() {
      const getQuestionNumberStyle = () => {
        switch (this.type.toUpperCase()) {
          case "SAT":
            return "background:#000000;border-radius:4px;color:#ffffff;margin:0px 4px;padding:0px 4px;";
          case "ACT":
            return "border:1px solid #000000;margin:0px 4px;padding:0px 4px;height: 36px;";
          default:
            return "";
        }
      };

      const passageContent = $(".passage-content");
      $.each(passageContent, function(key, val) {
        for (let i = 0; i < $(val).find("span").length; i++) {
          if ($(val).find("span")[i].className && $(val).find("span")[i].className.includes("Question")) {
            $(val).find("span")[i].style = getQuestionNumberStyle();
          }
        }
      });
    },
    setQuestionNumberStyleWithBaseline() {
      switch (this.type.toUpperCase()) {
        case "SAT":
          this.setQuestionNumberSatStyleWithBaseline();
          break;
        case "ACT":
          this.setQuestionNumberActStyleWithBaseline();
          break;
        default:
      };
    },
    setQuestionNumberSatStyleWithBaseline() {
      const getCurrentPassageTargetBaselineIndex = (passageContent) => {
        let targetIndex = -1;
        if (passageContent.includes("</u><span")) {
          targetIndex = passageContent.indexOf("</u><span");
        } else {
          targetIndex = passageContent.indexOf("</span><u");
        }
        return targetIndex;
      };

      let targetIndex = null;

      const passageContents = $(".passage-content");
      $.each(passageContents, function(index, passageContentElement) {
        let passageContent = passageContentElement.innerHTML;
        while (passageContent.includes("</u><span")) {
          // take span tag
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);
          let spanTag = "";
          const startSpanTagIndex = targetIndex + 4;
          let endSpanTagIndex = startSpanTagIndex;
          while (!spanTag.includes("</span>")) {
            spanTag = `${spanTag}${passageContent[endSpanTagIndex]}`;
            endSpanTagIndex += 1;
          }
          passageContent = passageContent.replace(spanTag, "");

          // find start index of u tag
          let startUTagIndex = targetIndex;
          let uTag = "";
          while (!uTag.includes("<u>")) {
            uTag = `${passageContent[startUTagIndex]}${uTag}`;
            startUTagIndex -= 1;
          }
          // insert span tag on the front u tag
          passageContent = `${passageContent.slice(0, startUTagIndex + 1)}${spanTag}${passageContent.slice(startUTagIndex + 1)}`;
        }

        $(".passage-content")[index].innerHTML = passageContent;
      });
    },
    setQuestionNumberActStyleWithBaseline() {
      const getCurrentPassageTargetBaselineIndex = (passageContent) => {
        let targetIndex = -1;
        if (passageContent.includes("</u><span")) {
          targetIndex = passageContent.indexOf("</u><span");
        } else {
          targetIndex = passageContent.indexOf("</span><u");
        }
        return targetIndex;
      };

      let targetIndex = null;

      const passageContents = $(".passage-content");
      $.each(passageContents, function(index, passageContentElement) {
        let passageContent = passageContentElement.innerHTML;
        while (passageContent.includes("</u><span") || passageContent.includes("</span><u")) {
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);

          // remove <span> style
          let str = "";
          if (passageContent.includes("</u><span")) {
            let closeSpanTagIndex =  targetIndex + 8;
            while (passageContent[closeSpanTagIndex] !== ">") {
              closeSpanTagIndex += 1;
            }
            passageContent = `${passageContent.slice(0, targetIndex + 9)}${passageContent.slice(closeSpanTagIndex)}`;
          } else {
            let closeSpanTagIndex =  targetIndex;
            while (passageContent[closeSpanTagIndex] !== ">") {
              closeSpanTagIndex -= 1;
            }
            let startSpanTagIndex = closeSpanTagIndex - 1;
            while (passageContent[startSpanTagIndex] !== "<") {
              startSpanTagIndex -= 1;
            }
            passageContent = `${passageContent.slice(0, startSpanTagIndex + 5)}${passageContent.slice(closeSpanTagIndex)}`;
          }
  
          // wrap <p>'s <div>  
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);
          let startPTagIndex = targetIndex;
          str = "";
          while(!str.includes("<p>")) {
            startPTagIndex -= 1;
            str = `${passageContent[startPTagIndex]}${str}`;
          }

          let endPTagIndex = targetIndex;
          while(!str.includes("</p>")) {
            str += passageContent[endPTagIndex];
            endPTagIndex += 1;
          }
          if (!str.includes("<div style=\"display: flex; margin-bottom: -14px;\">")) {
            str = str.replace("<p>", "<p><div style=\"display: flex; margin-bottom: -14px;\">").replace("</p>", "</div></p>");
            passageContent = `${passageContent.slice(0, startPTagIndex)}${str}${passageContent.slice(endPTagIndex)}`;
          }

          // wrap question number's <div>
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);
          if (passageContent.includes("</u><span>")) {
            let startUTagIndex = targetIndex - 1;
            let str = "";
            str = "";
            while(!str.includes("<u>")) {
              str = `${passageContent[startUTagIndex]}${str}`;
              startUTagIndex -= 1;
            }
            let endUTagIndex = targetIndex;
            while(!str.includes("</span>")) {
              str += passageContent[endUTagIndex];
              endUTagIndex += 1;
            }
            str = str.replace("<u>", "<div style=\"display: flex; flex-direction: column; align-items: center;\"><u style=\"margin-bottom: -18px;\">").replace("</span>", "</span></div>");
            passageContent = `${passageContent.slice(0, startUTagIndex + 1)}${str}${passageContent.slice(endUTagIndex)}`;
          } else {
            let startSpanTagIndex = targetIndex - 1;
            let str = "";
            str += passageContent[startSpanTagIndex];
            while(!str.includes("<span>")) {
              startSpanTagIndex -= 1;
              str = `${passageContent[startSpanTagIndex]}${str}`;
            }

            let endUTagIndex = targetIndex;
            while(!str.includes("</u>")) {
              str += passageContent[endUTagIndex];
              endUTagIndex += 1;
            }

            str = str.replace("<span>", "<div style=\"display: flex; flex-direction: column-reverse; align-items: center;\"><span style=\"margin-top: -18px;\">").replace("</u>", "</u></div>");
            passageContent = `${passageContent.slice(0, startSpanTagIndex)}${str}${passageContent.slice(endUTagIndex)}`;
          }

          // check if <u> connected pervious line
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);
          if (passageContent.includes("</u><span>")) {
            str = "";
            let startPTagIndex = targetIndex;
            while (!str.includes("<p>")) {
              startPTagIndex -= 1;
              str = `${passageContent[startPTagIndex]}${str}`;
            }

            let endSpanTagIndex = targetIndex;
            let questionNumber = "";
            while(!questionNumber.includes("</span>")) {
              questionNumber += passageContent[endSpanTagIndex];
              endSpanTagIndex += 1;
            }
            questionNumber = questionNumber.replace("</span>", "").replace("</u><span>", "");

            if (str.includes("<p><div style=\"display: flex; margin-bottom: -14px;\"><div style=\"display: flex; flex-direction: column; align-items: center;\"><u style=\"margin-bottom: -18px;\">")) {
              const addedQuestionPassageContent = passageContent.slice(0, startPTagIndex).replaceAll("</u></p>", `</u><span class="actQuestionNumber" style="border: 1px solid rgb(0, 0, 0); margin: 0px 4px; padding: 0px 4px; height: 36px;">${questionNumber}</span></p>`);
              passageContent = `${addedQuestionPassageContent}${passageContent.slice(startPTagIndex)}`;
            }
            passageContent = passageContent.replace("</u><span>", "</u><templateBlock /><span>");
          } else {
            str = "";
            let endPTagIndex = targetIndex;
            while (!str.includes("</p>")) {
              endPTagIndex += 1;
              str += passageContent[endPTagIndex];
            }

            let questionNumberIndex = targetIndex - 1;
            let questionNumber = "";
            while (passageContent[questionNumberIndex] !== ">") {
              questionNumber = `${passageContent[questionNumberIndex]}${questionNumber}`;
              questionNumberIndex -= 1;
            }
            
            if (str.includes("</u></div></div></p>")) {
              const nextLineStartPTagIndex = endPTagIndex + 6;
              const addedQuestionPassageContent = `${passageContent.slice(0, nextLineStartPTagIndex)}<span class="actQuestionNumber" style="border: 1px solid rgb(0, 0, 0); margin: 0px 4px; padding: 0px 4px; height: 36px;">${questionNumber}</span>`;
              passageContent = `${addedQuestionPassageContent}${passageContent.slice(nextLineStartPTagIndex)}`;
            }
            passageContent = passageContent.replace("</span><u>", "</span><templateBlock /><u>");
          }
        }
        passageContent = passageContent.replaceAll("<templateBlock />", "").replaceAll("<p></p>", "");
        $(".passage-content")[index].innerHTML = passageContent;
      });

    }
  }
};
</script>

<style scoped src="@/views/toefl/toeflTest/style/tpo.css"></style>
<style scoped>
.passage-content, .passage-intro {
  line-height: 34px;
}
.passage-content >>> p{
  margin-bottom: 0px;
  white-space: nowrap;
}
.passage-intro >>> p {
  margin-bottom: 0px;
}
</style>
